import { api as apiSora, FPNumber, connection as soraConnection } from '@sora-substrate/util';
import { ApiPromise } from '@polkadot/api';
import { DOTSAMA_AUTO_CONNECT_MS } from '@extension-base/const/intervals';
import { NETWORK_STATUS } from '@extension-base/api/types/networks';
import { WsProvider } from '@polkadot/rpc-provider';
import type { ApiInterfaceEvents } from '@polkadot/api/types';
import type { ProviderInterfaceEmitCb } from '@polkadot/rpc-provider/types';
import type { NetworkService } from '@extension-base/services/network-service';
import type { NetworkJson } from '@extension-base/types';
import type { ApiProps } from '@extension-base/background/types/types';
import type State from '@extension-base/background/handlers/State';
import type { NetworkName, SoraFees } from '@/interfaces';
import { isSora } from '@/helpers';
import { AUTO_CONNECT_MS, MAX_CONTINUE_RETRY } from '@/consts/networks';

export class SubstrateApiHandler {
  api: Record<NetworkName, ApiProps> = {};

  constructor(readonly networkService: NetworkService, public state: State) {}

  async initApi(network: NetworkJson): Promise<void> {
    const { name, nodes } = network;
    const networkName = name.toLowerCase();

    if (this.api[networkName] === undefined) this.api[networkName] = this.createApiObject();
    const { nodeIndex } = this.api[networkName];

    const autoSelectNode = network.isManual ? null : nodes[nodeIndex].url;
    const currentProvider = autoSelectNode ?? network.currentProvider;
    const eventListeners: Array<[ApiInterfaceEvents, ProviderInterfaceEmitCb]> = [
      ['connected', () => this.onConnected(networkName)],
      ['disconnected', () => this.onDisconnect(name)],
      ['ready', () => this.onReady(networkName)],
      ['error', () => null],
    ];

    if (isSora(networkName)) {
      soraConnection.open(currentProvider, { autoConnectMs: AUTO_CONNECT_MS, eventListeners });

      return;
    }

    try {
      const provider = new WsProvider(currentProvider, DOTSAMA_AUTO_CONNECT_MS, undefined, 10000);

      this.api[networkName].api = new ApiPromise({ provider, noInitWarn: true });
      this.api[networkName].provider = provider;

      eventListeners.forEach(([eventName, callback]) => this.api[networkName].api?.on(eventName, callback));
    } catch {
      this.onDisconnect(networkName);
    }
  }

  createApiObject(): ApiProps {
    return {
      isEthereum: false,
      apiStatus: NETWORK_STATUS.CONNECTING,
      apiRetry: 0,
      nodeIndex: 0,
    };
  }

  onConnected(networkName: string) {
    if (isSora(networkName)) this.api[networkName].api = soraConnection.api!;

    this.api[networkName].apiRetry = 0;
    this.api[networkName].apiStatus = NETWORK_STATUS.CONNECTED;
  }

  async onDisconnect(networkName: string) {
    const api = this.api[networkName.toLowerCase()];
    const netName = this.networkService.getNetworkJson(networkName).name;
    const network = this.networkService.networkMap[netName];

    if (api === undefined) {
      this.state.subscriptionService.getSubscription(networkName)?.(); //clean up;

      return;
    }

    api.apiRetry += 1;

    if (api.apiRetry < MAX_CONTINUE_RETRY) return;

    api.provider?.disconnect();

    api.nodeIndex += 1;

    if (api.nodeIndex <= network.nodes.length - 1) {
      api.apiRetry = 0;
      api.provider = undefined;
      api.api = undefined;

      if (navigator.onLine) this.initApi(network);
      else {
        api.apiStatus = NETWORK_STATUS.DISCONNECTED;
        this.state.disableNetworkMap(networkName);
      }
    } else {
      api.apiStatus = NETWORK_STATUS.DISCONNECTED; // попробовали все ноды, не смогли подключиться, ставим статус дисконнект

      this.state.disableNetworkMap(networkName);
    }
  }

  async onReady(networkName: string) {
    if (isSora(networkName)) {
      await apiSora.initialize(false);
      await apiSora.calcStaticNetworkFees();

      const fees = Object.fromEntries(
        Object.entries(apiSora.NetworkFee).map(([operation, value]) => [
          operation,
          FPNumber.fromCodecValue(value).toString(),
        ])
      ) as SoraFees;

      this.state.soraFees.next(fees);
      this.state.subscribeTotalXorBalance();
    }

    const account = this.state.currentAccount;

    if (!account) return;

    this.state.subscriptionService.getSubscription(networkName)?.();
    this.state.subscriptionService.subscribeBalances(account.address, account.ethereumAddress, [networkName], []);
    this.state.balanceService.updateUtilityED(networkName);
  }

  public refreshDotSamaApi(key: string) {
    if (this.api[key]) {
      this.api[key].nodeIndex = 0;
      this.api[key].apiRetry = 0;
    }

    const network = this.networkService.getNetworkJson(key);

    this.initApi(network);
  }

  resetApiRetries() {
    Object.values(this.api).forEach((api) => {
      api.nodeIndex = 0;
      api.apiRetry = 0;
    });
  }
}
