export const CRON_REFRESH_PRICE_INTERVAL = 90000; // 90 sec
export const REFRESH_PRICE_INTERVAL = 600000; // 10 min
export const DOTSAMA_API_TIMEOUT = 30000; // 30 sec
export const DOTSAMA_AUTO_CONNECT_MS = 3000;
export const DOTSAMA_MAX_CONTINUE_RETRY = 2;
export const CRON_AUTO_RECOVER_DOTSAMA_INTERVAL = 90000;
export const CRON_AUTO_RECOVER_WEB3_INTERVAL = 90000;
export const SUB_TOKEN_REFRESH_BALANCE_INTERVAL = 30000;
export const SUBSCRIBE_BALANCE_FAST_INTERVAL = 9000;
export const CRON_GET_API_MAP_STATUS = 5000;
export const CRON_UPDATE_JSON_INTERVAL = 86400000; // 1 day
export const ETHEREUM_REFRESH_BALANCE_INTERVAL = 20000; // 20 sec
